<template>
  <div class="dress">
    <v-card>
      <v-card-title> Crear Alquiler </v-card-title>
      <v-card-text>
        <v-stepper v-model="stepper">
          <v-stepper-header>
            <v-stepper-step :complete="stepper > 1" step="1">
              Cliente
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="stepper > 2" step="2">
              Alquiler
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="stepper > 3" step="3">
              Vestidos
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="4"> Pagos </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-progress-linear
                value="15"
                indeterminate
                absolute
                top
                v-if="this.$store.state.client.isLoading"
              ></v-progress-linear>
              <v-form ref="formClient" v-model="formClient">
                <v-container>
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="modelCLient.colombian_id"
                        :rules="[rules.required]"
                        label="Identificación"
                        required
                        @change="getClient"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="modelCLient.first_name"
                        :rules="[rules.required]"
                        label="Nombres"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="modelCLient.last_name"
                        :rules="[rules.required]"
                        label="Apellidos"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="modelCLient.phone"
                        :rules="[rules.required]"
                        label="Teléfono"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="modelCLient.email"
                        label="Correo"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="modelCLient.address"
                        :rules="[rules.required]"
                        label="Dirección"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>

              <v-btn color="primary" @click="saveClient"> Continuar </v-btn>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-form ref="formLoan" v-model="formLoan">
                <v-container>
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-menu
                        v-model="menuLoanDate"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="model.loan_date"
                            label="Fecha de alquiler"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :rules="[rules.required]"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="model.loan_date"
                          @input="menuLoanDate = false"
                          locale="es-ES"
                          :max="new Date().toISOString().substr(0, 10)"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-menu
                        v-model="menuEventDate"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="model.event_date"
                            label="Fecha de salida"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :rules="[rules.required]"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="model.event_date"
                          @input="menuEventDate = false"
                          locale="es-ES"
                          :min="new Date().toISOString().substr(0, 10)"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-menu
                        v-model="menuReturnDate"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="model.return_date"
                            label="Fecha de entrada"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :rules="[rules.required]"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="model.return_date"
                          @input="menuReturnDate = false"
                          locale="es-ES"
                          :min="new Date().toISOString().substr(0, 10)"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="model.address"
                        :rules="[rules.required]"
                        label="Dirección"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="model.reference_name"
                        :rules="[rules.required]"
                        label="Nombre de referencia"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="model.reference_phone"
                        :rules="[rules.required]"
                        label="Teléfono de referencia"
                        required
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="model.deposit_value"
                        :rules="[rules.required]"
                        label="Depósito"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="model.address_value"
                        :rules="[rules.required]"
                        label="Domicilio"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" v-if="multilanguage">
                      <v-select
                        :items="idioms"
                        v-model="model.lan"
                        :rules="[rules.required]"
                        label="Idioma"
                        required
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-select
                        :items="employees"
                        item-text="full_name"
                        item-value="id"
                        label="Seleccione Asesor"
                        no-data-text="No hay registros..."
                        :rules="[rules.required]"
                        v-model="model.employee_id"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-textarea
                        v-model="model.note"
                        label="Nota / Observación"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>

              <v-btn color="primary" @click="nextStepDress"> Continuar </v-btn>
              <v-btn text @click="stepper = 1"> Volver </v-btn>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-container>
                <v-row>
                  <v-col cols="12" class="py-0">
                    <v-toolbar>
                      <v-toolbar-title>Lista de vestidos</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn
                        class="mx-2"
                        small
                        fab
                        dark
                        color="indigo"
                        @click="dialogAddDress = true"
                      >
                        <v-icon dark> mdi-plus </v-icon>
                      </v-btn>
                    </v-toolbar>
                  </v-col>
                </v-row>
                <v-row v-for="(dress, index) in model.dresses" :key="index">
                  <v-col cols="12">
                    <v-card>
                      <v-card-title>
                        <v-avatar
                          color="primary"
                          size="25"
                          class="white--text mr-3"
                          >{{ index + 1 }}</v-avatar
                        >
                        {{ dress.dress.code }} - {{ dress.dress.color }} -
                        Alquiler: {{ dress.rental_value | formatNumber }} -
                        Lavado: {{ dress.wash_value | formatNumber }}
                        <v-spacer></v-spacer>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              color="green"
                              v-bind="attrs"
                              v-on="on"
                              @click="editDress(dress)"
                            >
                              <v-icon dark> mdi-circle-edit-outline </v-icon>
                            </v-btn>
                          </template>
                          <span>Editar</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              color="red"
                              v-bind="attrs"
                              v-on="on"
                              @click="deleteDress(dress)"
                            >
                              <v-icon dark> mdi-delete </v-icon>
                            </v-btn>
                          </template>
                          <span>Eliminar</span>
                        </v-tooltip>
                      </v-card-title>
                      <v-card-text>
                        <h2 class="text-left mb-4">
                          <v-icon>mdi-content-cut</v-icon>
                          Arreglos
                        </h2>
                        <div
                          v-for="(arrangement, indexA) in dress.arrangements"
                          :key="indexA"
                        >
                          <p class="text-left">
                            <span>#{{ indexA + 1 }}</span> -
                            {{ arrangement.value | formatNumber }} -
                            {{ arrangement.description }}
                          </p>
                        </div>

                        <h2
                          class="text-left mb-4"
                          v-if="dress.photo_studio_date"
                        >
                          <v-icon>mdi-camera</v-icon>
                          Foto estudio
                        </h2>
                        <div class="text-left" v-if="dress.photo_studio_date">
                          Fecha: {{ dress.photo_studio_date }} <br />
                          Valor:
                          {{ dress.photo_studio_value | formatNumber }}
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row v-if="model.dresses.length == 0">
                  <v-col cols="12">
                    <v-card>
                      <v-card-title class="d-inline-block">
                        No hay registros agregados...
                      </v-card-title>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>

              <v-btn color="primary" @click="nextStepPayment">
                Continuar
              </v-btn>
              <v-btn text @click="stepper = 2"> Volver </v-btn>
            </v-stepper-content>

            <v-stepper-content step="4">
              <v-data-table
                :headers="headersPayment"
                :items="model.payments"
                class="elevation-1 mb-7"
                no-data-text="No hay registros agregados..."
                hide-default-footer
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>Pagos</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <span class="text-h6 font-weight-bold"
                      >Total a pagar:
                      {{ model.rental_value | formatNumber }}
                    </span>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialogAddPayment" max-width="500px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          v-bind="attrs"
                          v-on="on"
                        >
                          Agregar
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="headline"
                            >{{ titleDialogPayment }} Pago</span
                          >
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                          <v-form ref="formPayment" v-model="formAddPayment">
                            <v-container>
                              <v-row>
                                <v-col cols="12">
                                  <v-text-field
                                    v-model="modelPayment.value"
                                    label="Valor"
                                    :rules="[rules.required]"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                  <v-select
                                    v-model="modelPayment.payment_method"
                                    label="Metodo de pago"
                                    :rules="[rules.required]"
                                    :items="paymentMethods"
                                  ></v-select>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-form>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="closePayment"
                          >
                            Cancelar
                          </v-btn>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="savePayment"
                          >
                            {{ titleDialogPayment }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDeletePayment" max-width="500px">
                      <v-card>
                        <v-card-title class="headline"
                          >Seguro que desea eliminar este pago?</v-card-title
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="closeDeletePayment"
                            >Cancelar</v-btn
                          >
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="deletePaymentConfirm"
                            >Aceptar</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.actions`]="item">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="green"
                        v-bind="attrs"
                        v-on="on"
                        @click="editPayment(item.item)"
                      >
                        <v-icon dark> mdi-circle-edit-outline </v-icon>
                      </v-btn>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="red"
                        v-bind="attrs"
                        v-on="on"
                        @click="deletePayment(item.item)"
                      >
                        <v-icon dark> mdi-delete </v-icon>
                      </v-btn>
                    </template>
                    <span>Eliminar</span>
                  </v-tooltip>
                </template>
              </v-data-table>

              <v-btn color="primary" @click="saveLoan"> Guardar </v-btn>
              <v-btn text @click="stepper = 3"> Volver </v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
    </v-card>

    <!-- Dialog add dress -->
    <v-dialog v-model="dialogAddDress" persistent width="600">
      <v-card>
        <v-card-title>{{ titleDialogDress }} Vestido</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="py-5">
          <v-form ref="formAddDress" v-model="formAddDress">
            <v-select
              :items="categories"
              item-value="id"
              label="Seleccione categoría"
              item-text="name"
              no-data-text="No se encontraron resultados"
              v-model="modelAddDress.category"
              @change="getDressesByCategory"
              :rules="[rules.required]"
            >
            </v-select>

            <v-autocomplete
              :items="dresses"
              item-value="id"
              label="Seleccione el vestido"
              :item-text="(item) => `${item.code} - ${item.color}`"
              no-data-text="No se encontraron resultados"
              v-model="modelAddDress.dress"
              :rules="[rules.required]"
            >
            </v-autocomplete>

            <v-text-field
              v-model="modelAddDress.rental_value"
              :rules="[rules.required]"
              label="Valor de alquiler"
              required
            ></v-text-field>

            <v-text-field
              v-model="modelAddDress.wash_value"
              :rules="[rules.required]"
              label="Valor de lavado"
              required
            ></v-text-field>

            <v-switch
              v-model="photoStudio"
              label="Foto Estudio"
              hide-details
            ></v-switch>

            <v-menu
              v-model="menuPhotoStudio"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
              v-if="photoStudio"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="modelAddDress.photo_studio_date"
                  label="Fecha de foto estudio"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[rules.required]"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="modelAddDress.photo_studio_date"
                @input="menuPhotoStudio = false"
                locale="es-ES"
              ></v-date-picker>
            </v-menu>

            <v-text-field
              v-model="modelAddDress.photo_studio_value"
              :rules="[rules.required]"
              label="Valor de foto estudio"
              required
              v-if="photoStudio"
            ></v-text-field>

            <v-data-table
              :headers="headersArrangement"
              :items="modelAddDress.arrangements"
              class="elevation-1 mt-4"
              hide-default-footer
              no-data-text="No hay arreglos agregados..."
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>Arreglos</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>

                  <v-dialog v-model="dialogAddArrangement" max-width="500px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        dark
                        class="mb-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        Agregar
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="headline">Agregar Arreglo</span>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-form
                            ref="formAddArrangement"
                            v-model="formAddArrangement"
                          >
                            <v-row>
                              <v-col cols="12">
                                <v-textarea
                                  v-model="modelAddArrangement.description"
                                  label="Descripción del arreglo"
                                  :rules="[rules.required]"
                                  required
                                ></v-textarea>
                              </v-col>
                              <v-col cols="12" md="12">
                                <v-text-field
                                  v-model="modelAddArrangement.value"
                                  :rules="[rules.required]"
                                  label="Valor"
                                  required
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDialogA">
                          Cancelar
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="saveArrangement"
                        >
                          Agregar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                  <v-dialog v-model="dialogDeleteArrangement" max-width="500px">
                    <v-card>
                      <v-card-title class="headline"
                        >Seguro que desea eliminar este arreglo?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="closeDeleteArrangement"
                          >Cancelar</v-btn
                        >
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="deleteArrangementConfirm"
                          >Aceptar</v-btn
                        >
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:[`item.actions`]="item">
                <v-icon small @click="deleteArrangement(item.item)">
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="blue darken-1" @click="closeDialogDress">
            Cancelar
          </v-btn>
          <v-btn text color="blue darken-1" @click="saveDress">
            {{ titleDialogDress }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog delete dress -->
    <v-dialog v-model="dialogDeleteDress" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline"
          >Seguro que desea eliminar este vestido?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDeleteDress"
            >Cancelar</v-btn
          >
          <v-btn color="blue darken-1" text @click="deleteDressConfirm"
            >Aceptar</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog loader -->
    <v-dialog
      v-model="this.$store.state.loan.create.isLoading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card color="primary" dark>
        <v-card-text>
          Por favor espere...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog confirmation  -->
    <v-dialog v-model="confirmDialog.active" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline">{{
          confirmDialog.message
        }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="confirmDialog.active = false"
            >Cancelar</v-btn
          >
          <v-btn color="blue darken-1" text @click="processConfirmDialog"
            >Aceptar</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { SET_CLIENT } from "@/store/client";
import { SET_CATEGORIES } from "@/store/categories";
import { SET_EMPLOYEES } from "@/store/employee";
import { SET_DRESSES } from "@/store/dresses";
import { SET_ALERT } from "@/store/alert";
import { SET_USER } from "@/store/user";
import { SET_LOAN } from "@/store/loan";
import { mapGetters } from "vuex";
export default {
  name: "Create",
  props: ["id"],
  filters: {
    formatNumber(value) {
      if (typeof value === "string") {
        value = value.split(".").join("");
      }
      return new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
      }).format(value);
    },
  },
  data: () => ({
    currentClient: null,
    currentDress: null,
    currentPayment: null,
    formClient: true,
    formLoan: true,
    formAddDress: true,
    formAddArrangement: true,
    formAddPayment: true,
    stepper: 1,
    menuLoanDate: null,
    menuEventDate: null,
    menuReturnDate: null,
    menuPhotoStudio: null,
    dialogAddDress: false,
    dialogAddArrangement: false,
    dialogAddPayment: false,
    dialogDeleteArrangement: false,
    dialogDeleteDress: false,
    dialogDeletePayment: false,
    photoStudio: false,
    confirmDialog: {
      active: false,
      message: "",
      callFunction: "",
      item: null,
    },
    model: {
      client_id: "",
      loan_date: "",
      event_date: "",
      return_date: "",
      address: "",
      reference_name: "",
      reference_phone: "",
      rental_value: "",
      deposit_value: "",
      address_value: "",
      lan: "es",
      note: "",
      employee_id: "",
      dresses: [],
      payments: [],
    },
    modelCLient: {
      first_name: "",
      last_name: "",
      colombian_id: "",
      phone: "",
      email: "",
      address: "",
    },
    modelAddDress: {
      category: null,
      dress: null,
      arrangements: [],
      wash_value: "",
      rental_value: "",
      photo_studio_date: "",
      photo_studio_value: "",
    },
    modelAddArrangement: {
      description: "",
      value: "",
    },
    modelPayment: {
      value: "",
      payment_method: "",
    },
    colombian_id: "",
    rules: {
      required: (value) => !!value || "Campo obligatorio.",
    },
    loan: null,
    categories: [],
    dresses: [],
    employees: [],
    headersArrangement: [
      { text: "Descripción", value: "description", sortable: false },
      { text: "Valor", value: "value", sortable: false },
      { text: "Acción", value: "actions", sortable: false },
    ],
    headersPayment: [
      { text: "Valor", value: "value", sortable: false },
      { text: "Método de pago", value: "paymentMethodLan", sortable: false },
      { text: "Acción", value: "actions", sortable: false },
    ],
    idioms: [
      { text: "Español", value: "es" },
      { text: "Ingles", value: "en" },
    ],
    editedIndex: -1,
    editedIndexDress: -1,
    editedIndexPayment: -1,
    multilanguage: 0,
  }),
  created() {
    this.$store.dispatch("getCategoriesAll");
    if (this.id) {
      this.$store.dispatch("getLoan", this.id);
    }
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === SET_CLIENT) {
        this.currentClient = state.client.client;
        this.model.client_id = this.currentClient.id;
        this.setClient();
      }
      if (mutation.type === SET_CATEGORIES) {
        this.categories = state.categories.categories;
      }
      if (mutation.type === SET_DRESSES) {
        this.dresses = state.dresses.dresses;
        if (this.loan) {
          this.loan.dresses.forEach((item) => {
            this.dresses.push(item);
          });
        }
      }
      if (mutation.type === SET_USER) {
        this.multilanguage = state.user.user.office.multilanguage;
        if (state.user.user.office.id) {
          this.$store.dispatch(
            "getEmployeesAll",
            "office=" + state.user.user.office.id + "&position=ADVISER"
          );
        }
      }
      if (mutation.type === SET_EMPLOYEES) {        
        this.employees = state.employee.employees;
      }
      if (mutation.type === SET_LOAN) {
        this.setLoan(state.loan.loan);
      }
    });
  },
  mounted(){
    if(this.employees.length == 0){
      this.$store.dispatch("getUser");
    }
  },
  computed: {
    ...mapGetters(["getStatusLan", "getStatusColor"]),
    rental() {
      return this.model.rental_value;
    },
    deposit() {
      return this.model.deposit_value;
    },
    washed() {
      return this.modelAddDress.wash_value;
    },
    addressValue() {
      return this.model.address_value;
    },
    value() {
      return this.modelPayment.value;
    },
    rentalDress() {
      return this.modelAddDress.rental_value;
    },
    titleDialogDress() {
      return this.currentDress ? "Editar" : "Agregar";
    },
    titleDialogPayment() {
      return this.currentPayment ? "Editar" : "Agregar";
    },
    paymentMethods() {
      return this.$store.state.payments.paymentMethods;
    },
    valueArrangement() {
      return this.modelAddArrangement.value;
    },
  },
  watch: {
    deposit(val) {
      if (parseInt(val) > 0) {
        val = val.split(".").join("");
        this.model.deposit_value = new Intl.NumberFormat("de-DE").format(val);
      }
    },
    washed(val) {
      if (parseInt(val) > 0) {
        val = val.split(".").join("");
        this.modelAddDress.wash_value = new Intl.NumberFormat("de-DE").format(
          val
        );
      }
    },
    addressValue(val) {
      if (parseInt(val) > 0) {
        val = val.split(".").join("");
        this.model.address_value = new Intl.NumberFormat("de-DE").format(val);
      }
    },
    value(val) {
      if (parseInt(val) > 0) {
        val = val.split(".").join("");
        this.modelPayment.value = new Intl.NumberFormat("de-DE").format(val);
      }
    },
    rentalDress(val) {
      if (parseInt(val) > 0) {
        val = val.split(".").join("");
        this.modelAddDress.rental_value = new Intl.NumberFormat("de-DE").format(
          val
        );
      }
    },
    valueArrangement(val) {
      if (parseInt(val) > 0) {
        val = val.split(".").join("");
        this.modelAddArrangement.value = new Intl.NumberFormat("de-DE").format(
          val
        );
      }
    },
  },
  methods: {
    async getClient() {
      this.currentClient = null;
      this.colombian_id = this.modelCLient.colombian_id;
      this.$store.dispatch("getClient", this.colombian_id);
      await this.$refs.formClient.reset();
      this.modelCLient.colombian_id = this.colombian_id;
    },
    setClient() {
      this.modelCLient = {
        first_name: this.currentClient.first_name,
        last_name: this.currentClient.last_name,
        colombian_id: this.currentClient.colombian_id,
        phone: this.currentClient.phone,
        email: this.currentClient.email,
        address: this.currentClient.address,
      };
    },
    async saveClient() {
      if (!this.$refs.formClient.validate()) {
        this.$store.commit(SET_ALERT, {
          message: "Todos los campos son obligatorios",
          type: "error",
        });
        return;
      }

      if (!this.currentClient) {
        await this.$store.dispatch("createClient", this.modelCLient);
        await this.$store.dispatch("getClient", this.colombian_id);
      } else {
        this.$store.dispatch("updateClient", {
          id: this.currentClient.id,
          form: this.modelCLient,
        });
      }

      this.model.address = this.modelCLient.address;
      this.stepper = 2;
    },
    nextStepDress() {
      if (!this.$refs.formLoan.validate()) {
        this.$store.commit(SET_ALERT, {
          message: "Todos los campos son obligatorios",
          type: "error",
        });
        return;
      }
      this.stepper = 3;
    },
    getDressesByCategory() {
      this.$store.dispatch("getDressesByCategory", {
        category_id: this.modelAddDress.category,
        event_date: this.model.event_date,
        return_date: this.model.return_date,
      });
    },
    deleteArrangement(item) {
      this.editedIndex = this.modelAddDress.arrangements.indexOf(item);
      if (this.loan) {
        this.activateConfirmDialog(
          "deleteArrangementConfirm",
          item,
          `Seguro que desea eliminar este arreglo?`
        );
        return;
      }
      this.dialogDeleteArrangement = true;
    },
    deleteArrangementConfirm() {
      if (this.loan) {
        this.$store.dispatch("deleteArrangement", this.confirmDialog.item.id);
      }
      this.modelAddDress.arrangements.splice(this.editedIndex, 1);
      this.closeDeleteArrangement();
    },
    closeDialogA() {
      this.dialogAddArrangement = false;
      this.$nextTick(() => {
        this.$refs.formAddArrangement.resetValidation();
        this.modelAddArrangement = {
          description: "",
          value: "",
        };
      });
    },
    closeDeleteArrangement() {
      this.dialogDeleteArrangement = false;
      this.editedIndex = -1;
    },
    saveArrangement() {
      if (!this.$refs.formAddArrangement.validate()) {
        this.$store.commit(SET_ALERT, {
          message: "Todos los campos son obligatorios",
          type: "error",
        });
        return;
      }
      this.modelAddDress.arrangements.push({
        description: this.modelAddArrangement.description,
        value: this.modelAddArrangement.value,
      });
      this.closeDialogA();
    },
    editDress(item) {
      this.editedIndexDress = this.model.dresses.indexOf(item);
      this.currentDress = item;
      this.modelAddDress = {
        category: item.category,
        dress: item.id,
        rental_value: item.rental_value,
        wash_value: item.wash_value,
        photo_studio_date: item.photo_studio_date,
        photo_studio_value: item.photo_studio_value,
        arrangements: [...item.arrangements],
      };
      if (this.loan) {
        this.modelAddDress.loan_dress_id = item.loan_dress_id;
      }
      if (item.photo_studio_value != "") {
        this.photoStudio = true;
      }
      this.getDressesByCategory();
      this.dialogAddDress = true;
    },
    deleteDress(item) {
      this.editedIndexDress = this.model.dresses.indexOf(item);
      if (this.loan) {
        this.activateConfirmDialog(
          "deleteDressConfirm",
          item,
          `Seguro que desea eliminar el vestido ${item.code} de este alquiler?`
        );
        return;
      }
      this.dialogDeleteDress = true;
    },
    deleteDressConfirm() {
      if (this.loan) {
        this.$store.dispatch(
          "deleteLoanDress",
          this.confirmDialog.item.loan_dress_id
        );
      }
      this.model.dresses.splice(this.editedIndexDress, 1);
      this.closeDeleteDress();
    },
    closeDialogDress() {
      this.dialogAddDress = false;
      this.$nextTick(() => {
        this.currentDress = null;
        this.$refs.formAddDress.resetValidation();
        this.modelAddDress = {
          category: null,
          dress: null,
          rental_value: "",
          photo_studio_date: "",
          photo_studio_value: "",
          arrangements: [],
        };
        this.photoStudio = false;
        this.editedIndexDress = -1;
      });
    },
    closeDeleteDress() {
      this.dialogDeleteDress = false;
      this.editedIndexDress = -1;
    },
    saveDress() {
      if (!this.$refs.formAddDress.validate()) {
        this.$store.commit(SET_ALERT, {
          message: "Todos los campos son obligatorios",
          type: "error",
        });
        return;
      }
      var dress = this.dresses.find(
        (dress) => dress.id == this.modelAddDress.dress
      );
      const obj = {
        id: this.modelAddDress.dress,
        category: this.modelAddDress.category,
        rental_value: this.modelAddDress.rental_value,
        wash_value: this.modelAddDress.wash_value,
        dress: dress,
        photo_studio_date: this.modelAddDress.photo_studio_date,
        photo_studio_value: this.modelAddDress.photo_studio_value,
        arrangements: [...this.modelAddDress.arrangements],
      };
      if (this.currentDress) {
        Object.assign(this.model.dresses[this.editedIndexDress], obj);
        if (this.loan) {
          this.$store.dispatch("updateLoanDress", {
            id: this.modelAddDress.loan_dress_id,
            form: obj,
          });
        }
      } else {
        this.model.dresses.push(obj);
      }
      this.closeDialogDress();
    },
    nextStepPayment() {
      if (this.model.dresses.length == 0) {
        this.$store.commit(SET_ALERT, {
          message: "Debe agregar mínimo un vestido",
          type: "error",
        });
        return;
      }
      this.setNumber();
      this.stepper = 4;
    },
    editPayment(item) {      
      this.editedIndexPayment = this.model.payments.indexOf(item);
      this.currentPayment = Object.assign({}, item);
      this.modelPayment = Object.assign({}, item);
      this.dialogAddPayment = true;
    },
    deletePayment(item) {
      this.editedIndexPayment = this.model.payments.indexOf(item);
      this.currentPayment = Object.assign({}, item);
      if (this.loan) {
        this.activateConfirmDialog(
          "deletePaymentConfirm",
          item,
          `Seguro que desea eliminar el pago por valor de ${item.value}?`
        );
        return;
      }
      this.dialogDeletePayment = true;
    },
    deletePaymentConfirm() {      
      if (this.loan) {
        this.$store.dispatch(
          "deleteLoanPayment",
          this.currentPayment.payment_id
        );
      }
      this.model.payments.splice(this.editedIndexPayment, 1);
      this.closeDeletePayment();
    },
    closePayment() {
      this.dialogAddPayment = false;
      this.$nextTick(() => {
        this.currentPayment = null;
        this.modelPayment = {
          value: "",
          payment_method: "",
        };
        this.editedIndexPayment = -1;
        this.$refs.formPayment.resetValidation();
      });
    },
    closeDeletePayment() {
      this.dialogDeletePayment = false;
      this.$nextTick(() => {
        this.currentPayment = null;
        this.editedIndexPayment = -1;
      });
    },
    savePayment() {
      if (this.currentPayment) {
        Object.assign(
          this.model.payments[this.editedIndexPayment],
          this.modelPayment
        );
        if (this.loan) {
          this.$store.dispatch("updateLoanPayment", {
            id: this.currentPayment.payment_id,
            form: this.modelPayment,
          });
        }
      } else {
        const paymentMethod = this.paymentMethods.find(
          (method) => method.value == this.modelPayment.payment_method
        );
        this.modelPayment.paymentMethodLan = paymentMethod.text;
        this.model.payments.push(this.modelPayment);
      }
      this.closePayment();
    },
    async saveLoan() {
      if (this.model.payments.length == 0) {
        this.$store.commit(SET_ALERT, {
          message: "Debe agregar mínimo un pago",
          type: "error",
        });
        return;
      }
      this.setNumber();
      if (this.loan) {
        this.$store.dispatch("updateLoan", {
          id: this.loan.id,
          form: this.model,
        });
      } else {
        this.$store.dispatch("createLoan", this.model);
      }
    },
    validateValues() {
      var total = 0;
      total =
        parseFloat(this.model.deposit_value) +
        parseFloat(this.model.address_value);
      this.model.dresses.forEach((element) => {
        total +=
          parseFloat(element.rental_value) + parseFloat(element.wash_value);
        total +=
          element.photo_studio_value !== ""
            ? parseFloat(element.photo_studio_value)
            : 0;
        element.arrangements.forEach((item) => {
          total += parseFloat(item.value);
        });
      });
      this.model.rental_value = total;
    },
    setNumber() {
      if (typeof this.model.rental_value === "string")
        this.model.rental_value = this.model.rental_value.split(".").join("");

      if (typeof this.model.deposit_value === "string")
        this.model.deposit_value = this.model.deposit_value.split(".").join("");

      if (typeof this.model.address_value === "string")
        this.model.address_value = this.model.address_value.split(".").join("");

      this.model.payments.forEach((element) => {
        if (typeof element.value === "string")
          element.value = element.value.split(".").join("");
      });

      this.model.dresses.forEach((element) => {
        if (typeof element.rental_value === "string")
          element.rental_value = element.rental_value.split(".").join("");

        if (typeof element.wash_value === "string")
          element.wash_value = element.wash_value.split(".").join("");

        if (typeof element.photo_studio_value === "string")
          element.photo_studio_value = element.photo_studio_value
            .split(".")
            .join("");

        element.arrangements.forEach((item) => {
          if (typeof item.value === "string")
            item.value = item.value.split(".").join("");
        });
      });

      this.validateValues();
    },
    setLoan(loan) {
      this.loan = loan;
      this.currentClient = loan.client;
      this.model = {
        client_id: loan.client.id,
        loan_date: loan.loan_date,
        event_date: loan.event_date,
        return_date: loan.return_date,
        address: loan.address,
        reference_name: loan.reference_name,
        reference_phone: loan.reference_phone,
        rental_value: loan.rental_value,
        deposit_value: new Intl.NumberFormat("de-DE").format(
          parseInt(loan.deposit_value)
        ),
        address_value: new Intl.NumberFormat("de-DE").format(
          parseInt(loan.address_value)
        ),
        lan: "es",
        note: loan.note,
        employee_id: loan.employee.id,
        dresses: [],
        payments: [],
      };
      this.setClient();
      this.setDresses(loan.dresses);
      this.setPayments(loan.payments);
    },
    setDresses(dresses) {
      dresses.forEach((item) => {
        var arrangements = [];
        item.arrangements.forEach((arrangement) => {
          arrangements.push({
            id: arrangement.id,
            description: arrangement.description,
            value: new Intl.NumberFormat("de-DE").format(
              parseInt(arrangement.value)
            ),
          });
        });

        var obj = {          
          id: item.id,
          code: item.code,
          loan_dress_id: item.pivot.id,
          category: item.category.id,
          dress: item,
          rental_value: new Intl.NumberFormat("de-DE").format(
            parseInt(item.pivot.rental_value)
          ),
          wash_value: new Intl.NumberFormat("de-DE").format(
            parseInt(item.pivot.wash_value)
          ),          
          arrangements: [...arrangements],        
        };

        if(parseInt(item.pivot.photo_studio_value) > 0){
          obj.photo_studio_date = item.pivot.photo_studio_date;
          obj.photo_studio_value = new Intl.NumberFormat("de-DE").format(
            parseInt(item.pivot.photo_studio_value)
          );
        }else{
          obj.photo_studio_date = 0;
          obj.photo_studio_value = 0;
        }

        this.model.dresses.push(obj);
      });
    },
    setPayments(payments) {
      payments.forEach((item) => {
        const paymentMethod = this.paymentMethods.find(
          (method) => method.value == item.payment_method
        );

        this.model.payments.push({
          payment_id: item.id,
          value: new Intl.NumberFormat("de-DE").format(parseInt(item.value)),
          payment_method: item.payment_method,
          paymentMethodLan: paymentMethod.text,
        });
      });
    },
    activateConfirmDialog(callFunction, item, message) {
      this.confirmDialog.callFunction = callFunction;
      this.confirmDialog.message = message;
      this.confirmDialog.item = item;
      this.confirmDialog.active = true;
    },
    processConfirmDialog() {
      this[this.confirmDialog.callFunction]();
      this.deactivateConfirmDialog();
    },
    deactivateConfirmDialog() {
      this.confirmDialog.active = false;
      this.confirmDialog.callFunction = "";
      this.confirmDialog.message = "";
      this.confirmDialog.item = null;
    },
  },
};
</script>
